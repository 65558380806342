// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-code-js": () => import("./../src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-ads-author-networks-index-js": () => import("./../src/pages/portfolio/adsAuthorNetworks/index.js" /* webpackChunkName: "component---src-pages-portfolio-ads-author-networks-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-progressive-mass-index-js": () => import("./../src/pages/portfolio/progressiveMass/index.js" /* webpackChunkName: "component---src-pages-portfolio-progressive-mass-index-js" */),
  "component---src-pages-portfolio-reaction-gif-generator-index-js": () => import("./../src/pages/portfolio/reactionGifGenerator/index.js" /* webpackChunkName: "component---src-pages-portfolio-reaction-gif-generator-index-js" */),
  "component---src-pages-portfolio-redesigning-ads-index-js": () => import("./../src/pages/portfolio/redesigningADS/index.js" /* webpackChunkName: "component---src-pages-portfolio-redesigning-ads-index-js" */)
}

